import React, { useState, useEffect } from "react";
import salyImage from "../assets/saly_ajebuta.png";
import emailImg from "../assets/email.png";
import user from "../assets/user.png";
import BankTransfer from "../components/BankTransfer";
import { TopUp } from "../components/TopUp";
import { Link, useNavigate } from "react-router-dom";
import iconsPay from "../assets/icons_pay.png";
import axios from "axios";
import { toast } from "react-toastify";
import { FaLock, FaSpinner } from "react-icons/fa6";
import Cookies from "js-cookie";

const SignUp = () => {
  const [details, setDetails] = useState({
    fullName: "",
    email: "",
    referralCode: "",
    password: "",
    confirm: "",
    accountName: "",
    number: "",
    bank: "",
    bankCode: "",
    toogle: false,
  });
  const [isChecked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [regBtn, setRegBtn] = useState(false);
  const [proceed, setProceed] = useState(false);
  const navigate = useNavigate();
  const token = Cookies.get("token");

  useEffect(() => {
    token && navigate("/leaderboard");
  }, [token]);

  const handleCheckbox = () => setChecked(!isChecked);

  async function handleRefCodeVerify() {
    if (details.referralCode) {
      setLoader(true);
      try {
        const response = await axios.get(
          `https://ajebuta-referral.ajebur.com/api/retrieve/details/${details.referralCode}`
        );

        if (response?.data.status) {
          toast.success(response?.data.message);
          setDetails({
            ...details,
            fullName: response?.data.data.name,
            email: response?.data.data.email,
          });
          setProceed(true);
        } else {
          toast.warn("Invalid referral code!");
          setProceed(false);
        }
      } catch (error) {
        console.error(error);
        setProceed(false);
        toast.error("An error has occured!");
      } finally {
        setLoader(false);
      }
    } else {
      toast.warn("Referral code can not be empty!");
    }
  }

  async function handleRegister() {
    if (details.password && details.password === details.confirm) {
      setRegBtn(true);
      const data = {
        password: details.password,
        name: details.fullName,
        email: details.email,
        bank: "zenith bk",
        accountName: "My bank account",
        accountNumber: 8394899393,
        refCode: details.referralCode,
        payoutMethod: true ? "ajcoin" : "bank",
        bankCode: details.bankCode,
      };
      try {
        const response = await axios.post(
          `https://ajebuta-referral.ajebur.com/api/register/affiliate`,
          data
        );

        if (response?.data.status) {
          toast.success(response?.data.message);
          setDetails({
            ...details,
            fullName: response?.data.data.name,
            email: response?.data.data.email,
          });
          setTimeout(() => navigate("/login"), 2000);
          setProceed(true);
        } else {
          toast.warn(response?.data.message);
          setProceed(false);
          setDetails({
            ...details,
            accountName: "",
            number: "",
          });
          setChecked(false);
        }
      } catch (error) {
        console.error(error);
        setProceed(false);
        toast.error("An error has occured!");
      } finally {
        setRegBtn(false);
      }
    } else {
      toast.warn("Password does not match!");
    }
  }

  return (
    <div
      className={`max-w-screen-sm  m-auto p-4 py-24 pt-3 flex flex-col items-start`}
    >
      {/* First div */}
      <div className="w-full flex justify-between items-center ">
        <div className=" w-[40%]">
          <h6 className=" text-[#6F736D]">
            Join Ajebuta Affiliate Program & start earning!
          </h6>
        </div>
        <img src={salyImage} alt="sally" width={100} />
      </div>
      {/* Second div */}
      <div className="mt-3">
        <h1>Register</h1>
        <p>
          <span>
            Kindly note that AJAM is currently only available in these cities:
          </span>
          <span className="font-semibold ml-2">
            Abuja, Lagos, Port Harcourt, Enugu, Kano, Kaduna, Jos, Ibadan,
            Calabar, Benin Nigeria.
          </span>
        </p>
      </div>
      {/* Third div */}
      <div>
        <div className=" my-6">
          <h2 className=" font-semibold">Account Information</h2>
          <p>
            To get started you need to sign up on the Ajebuta app and receive a
            referral code. To get started, download, sign up and go to profile
            on the home page. Copy the referral code only. Then return back to
            this page to register. Make sure you are using the same name as on
            your bank account to receive your payment
          </p>
        </div>
        <div>
          <div className="border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
            <input
              type="text"
              name="referral code"
              required={true}
              placeholder="Enter referral code"
              value={details.referralCode}
              onChange={(e) =>
                setDetails({ ...details, referralCode: e.target.value })
              }
              className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
            />
            {loader ? (
              <button disabled>
                <FaSpinner className="animate-spin" />
              </button>
            ) : (
              <button onClick={handleRefCodeVerify}>verify</button>
            )}
          </div>
          <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
            <img src={user} alt="user" className=" w-8 mx-auto" />
            <input
              disabled
              type="text"
              name="username"
              required={true}
              placeholder="Full Name"
              value={details.fullName}
              onChange={(e) =>
                setDetails({ ...details, fullName: e.target.value })
              }
              className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
            />
          </div>
          <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
            <img src={emailImg} alt="email" className=" w-8 mx-auto" />
            <input
              disabled
              type="email"
              name="email"
              required={true}
              placeholder="Email"
              value={details.email}
              onChange={(e) =>
                setDetails({ ...details, email: e.target.value })
              }
              className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
            />
          </div>
          <div>
            <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
              <FaLock size={30} color="grey" />
              <input
                type="password"
                name="password"
                required={true}
                placeholder="Password"
                value={details.password}
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
                className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
              />
            </div>
            <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
              <FaLock size={30} color="grey" />
              <input
                type="password"
                name="confirm_password"
                required={true}
                placeholder="Confirm Password"
                value={details.confirm}
                onChange={(e) =>
                  setDetails({ ...details, confirm: e.target.value })
                }
                className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
              />
            </div>
          </div>
        </div>
      </div>
      {proceed && (
        <div>
          <div className=" my-6">
            <h2 className=" font-semibold">Payout Method</h2>
            <p>How would you like to receive your payment?</p>
          </div>
          <TopNav details={details} setToogle={setDetails} />
          {details.toogle === false ? (
            <BankTransfer details={details} setDetails={setDetails} />
          ) : (
            <TopUp />
          )}
          <div className="flex pt-4 ">
            <input
              type="checkbox"
              value={isChecked}
              onChange={handleCheckbox}
              className=" m-2 border checked:bg-[#E18762] active:border-[#E18762]  outline-none"
            />
            <p>
              I have read and agree to the Ajebuta Affiliate Marketing Program
              <span className=" text-[#E18762] ml-2">
                Terms and Conditions.
              </span>
            </p>
          </div>
          <div className="w-full mt-4">
            {regBtn ? (
              <button
                disabled
                className="text-white font-normal py-3 px-auto w-full rounded-xl capitalize"
              >
                <FaSpinner className="animate-spin mx-auto" />
              </button>
            ) : (
              <button
                disabled={!isChecked}
                onClick={handleRegister}
                className={`${
                  isChecked ? " " : " opacity-40 "
                } text-white font-normal py-3 px-auto w-full rounded-xl capitalize`}
              >
                register
              </button>
            )}
          </div>
        </div>
      )}

      <div className="flex space-x-2 justify-center w-full mt-4">
        <p className="text-base">Already have an account?</p>
        <Link to="/login">
          <p className="text-base capitalize text-green-500 cursor-pointer">
            login
          </p>
        </Link>
      </div>
    </div>
  );
};

const TopNav = ({ details, setToogle }) => {
  return (
    <button className=" w-[100%]  flex justify-between items-center ">
      <div
        onClick={() =>
          setToogle({ ...details, toogle: true, number: "", accountName: "" })
        }
        className={`${
          details.toogle === false ? "bg-transparent" : "bg-[#E1FAE5]"
        } rounded-lg p-2 w-[50%] flex items-center`}
      >
        <img src={iconsPay} alt="check" className=" w-4 mr-1" />
        <p
          className={
            details.toogle === false ? "text-[#E1FAE5]" : "text-[#6F736D]"
          }
        >
          AJCoin TopUp
        </p>
      </div>

      <div
        onClick={() => setToogle({ ...details, toogle: false })}
        className={`${
          details.toogle === true ? "bg-transparent" : "bg-[#E1FAE5]"
        } rounded-lg p-2 w-[50%] flex items-center`}
      >
        <img src={iconsPay} alt="check" className=" w-4 mr-1" />
        <p
          className={
            details.toogle === true ? "text-[#E1FAE5]" : "text-[#6F736D]"
          }
        >
          Bank Transfer
        </p>
      </div>
    </button>
  );
};

export default SignUp;
