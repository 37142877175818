import React, { FC } from "react";

const handleCopyClick = async () => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text successfully copied to clipboard");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
};

const AppButton = ({ buttonName, onClick, isRegister = false }) => {
  return (
    <div className="flex justify-center py-1">
      <button
        onClick={onClick}
        className={`${
          isRegister && "mx-auto opacity-80"
        } bg-blue-500 text-white font-bold py-3 px-auto w-72 rounded-xl`}
      >
        {buttonName}
      </button>
    </div>
  );
};

export default AppButton;
