import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomSelect from "./CustomSelect";
import { toast } from "react-toastify";

const BankTransfer = ({ details, setDetails }) => {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    async function fetchBanks() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer sk_test_e3a53e7ba554249e50e7beab2e2673227babfa65",
        },
      };
      try {
        const response = await axios.get(
          "https://api.paystack.co/bank",
          config
        );
        if (response?.data.status) {
          setBanks(response?.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchBanks();
  }, []);

  useEffect(() => {
    async function verifyBankAccountNumber() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer sk_test_e3a53e7ba554249e50e7beab2e2673227babfa65",
        },
      };
      try {
        const response = await axios.get(
          `https://api.paystack.co/bank/resolve?account_number=${details.number}&bank_code=${details.bankCode}`,
          config
        );

        if (response?.data.status) {
          toast.success(response?.data.message);
          setDetails({
            ...details,
            accountName: response?.data.data.account_name,
          });
        } else {
          toast.warn(response?.data.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("Error! Cross-check details");
      }
    }

    if (details.number.length === 10) {
      verifyBankAccountNumber();
    }
  }, [details.number]);

  return (
    <div className="items-center justify-between  pt-5">
      <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
        <CustomSelect banks={banks ?? []} setDetails={setDetails} />
      </div>
      <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
        <input
          type="text"
          name="account number"
          maxLength={10}
          minLength={10}
          required
          placeholder="* Account Number:"
          value={details.number}
          onChange={(e) => setDetails({ ...details, number: e.target.value })}
          className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
        />
      </div>

      <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
        <input
          disabled
          type="text"
          name="account name"
          required
          placeholder="* Account Name:"
          value={details.accountName}
          onChange={(e) =>
            setDetails({ ...details, accountName: e.target.value })
          }
          className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
        />
      </div>
    </div>
  );
};

export default BankTransfer;
