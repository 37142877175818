import React, { Component } from "react";
import OnboardingScreen from "./pages/onboarding";
import LearnMore from "./pages/learn_more";
import SignUp from "./pages/sign_up";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import InviteLeaderBoard from "./pages/InviteLeaderBoard";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

import { ToastContainer } from "react-toastify";
import Login from "./pages/login";

class App extends Component {
  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="colored"
        />

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<OnboardingScreen />} />
              <Route path="learn_more" element={<LearnMore />} />
              <Route path="register" element={<SignUp />} />
              <Route path="login" element={<Login />} />
              <Route
                path="leaderboard"
                element={
                  <AuthGuard>
                    <InviteLeaderBoard />
                  </AuthGuard>
                }
              />
              {/* <Route path="account_info" element={<AccountInfo />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}

export default App;

function AuthGuard({ children }) {
  const token = Cookies.get("token");
  if (!token) return location.replace("/login");
  return children;
}
