import React, { useRef, useState } from "react";
import AppButton from "../components/app_button";
import { FaRegCopy, FaSpinner } from "react-icons/fa6";
import iconsPay from "../assets/icons_pay.png";
import { toast } from "react-toastify";
import LeadersBoard from "../components/LeadersBoard";
import Referrals from "../components/referrals";
import axios from "axios";
import Cookies from "js-cookie";

const InviteLeaderBoard = () => {
  const [toogle, setToogle] = useState(false);
  const [loader, setLoader] = useState(false);
  const refCodeRef = useRef(null);
  const token = Cookies.get("token");

  // const handleCopyClick = (text) => {
  //   navigator.clipboard.writeText(text);
  //   toast.success("Copied to clipboard", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  //     transition: Bounce,
  //   });
  // };
  async function handleAddUser() {
    if (refCodeRef?.current?.value) {
      setLoader(true);
      const config = {
        url: "/add/referral",
        method: "post",
        baseURL: "https://ajebuta-referral.ajebur.com/api",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: { refCode: refCodeRef.current.value },
      };
      try {
        const response = await axios(config);
        if (response.data.status) {
          toast.success(response.data.message);
          refCodeRef.current.value = "";
        } else {
          toast.warn("The ref code has already been taken.");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    } else {
      toast.warn("Please, provide user referral code.");
    }
  }
  async function handleLogout() {
    const config = {
      url: "/logout",
      method: "post",
      baseURL: "https://ajebuta-referral.ajebur.com/api",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(config);
      if (response.data.status) {
        toast.success(response.data.message);
        Cookies.remove("token");
        location.replace("/login");
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div
      className={`max-w-screen-sm  m-auto p-6 py-24 pt-3 flex flex-col items-center justify-center`}
    >
      <div className=" pb-5 m-6 relative">
        <h1>Invite people</h1>
        <p>
          To start earning and increasing your points on the leaderboard, Start
          inviting people to download, Subscribe and use the Ajebuta App.
        </p>
        <button
          onClick={handleLogout}
          className="text-white font-normal py-3 px-auto rounded-xl capitalize absolute -top-3 -right-8"
        >
          logout
        </button>
      </div>
      <div>
        <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
          <input
            type="text"
            name="referral code"
            required
            placeholder="Enter user ref-code"
            ref={refCodeRef}
            className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
          />
        </div>
        <div className="w-full mt-4 mb-6">
          {loader ? (
            <button
              disabled
              className="text-white font-normal py-3 px-auto w-full rounded-xl capitalize"
            >
              <FaSpinner className="animate-spin mx-auto" />
            </button>
          ) : (
            <button
              onClick={handleAddUser}
              className="text-white font-normal py-3 px-auto w-full rounded-xl capitalize"
            >
              add new user
            </button>
          )}
        </div>
      </div>
      {/* <AppButton
        buttonName={
          <div className=" flex justify-between p-1">
            <p className=" text-white">Copy referral code: 42501234-AJB</p>
            <FaRegCopy />
          </div>
        }
        onClick={() => handleCopyClick("42501234-AJB")}
      />
      <AppButton
        buttonName={
          <div className=" flex justify-between p-1">
            <p className=" text-white">https://ajebuta.com/ref-42501234-AJB</p>
            <FaRegCopy />
          </div>
        }
        onClick={() => handleCopyClick("https://ajebuta.com/ref-42501234-AJB")}
      /> */}

      <div>
        <TopNav toogle={toogle} setToogle={setToogle} />
      </div>
      {!toogle ? <LeadersBoard /> : <Referrals />}
    </div>
  );
};

const TopNav = ({ toogle, setToogle }) => {
  return (
    <button className=" w-[22rem]  flex justify-between items-center m-4 ">
      <div
        onClick={() => setToogle(true)}
        className={`${
          toogle === false ? "bg-transparent" : "bg-[#E1FAE5]"
        } rounded-lg p-2 w-[50%] flex items-center`}
      >
        <img src={iconsPay} alt="check" className=" w-4 mr-1" />
        <p className={toogle === false ? "text-[#E1FAE5]" : "text-[#6F736D]"}>
          My Referrals
        </p>
      </div>

      <div
        onClick={() => setToogle(false)}
        className={`${
          toogle === true ? "bg-transparent" : "bg-[#E1FAE5]"
        } rounded-lg p-2 w-[50%] flex items-center`}
      >
        <img src={iconsPay} alt="check" className=" w-4 mr-1" />
        <p className={toogle === true ? "text-[#E1FAE5]" : "text-[#6F736D]"}>
          Leaderboard
        </p>
      </div>
    </button>
  );
};

export default InviteLeaderBoard;
