import React, { useState, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import AppButton from "./app_button";
import { Link } from "react-router-dom";
import { orangeColor } from "../colors";
import axios from "axios";
import Cookies from "js-cookie";

const Referrals = () => {
  const [referrals, setReferrals] = useState([]);
  const token = Cookies.get("token");

  useEffect(() => {
    async function fetchLeaders() {
      const config = {
        url: "/list/affiliate/referral",
        method: "get",
        baseURL: "https://ajebuta-referral.ajebur.com/api",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(config);
        if (response.data.status) {
          setReferrals(response.data.data);
        } else setReferrals([]);
      } catch (error) {
        console.error(error);
        setReferrals([]);
      }
    }
    fetchLeaders();
  }, []);
  //   {
  //     "id": 2,
  //     "referred_by": 3,
  //     "referral_code": "3363240018",
  //     "name": "Ume Obinna",
  //     "email": "linkebenezer@gmail.com",
  //     "rewarded_for": 0,
  //     "created_at": "2024-01-31T15:01:24.000000Z",
  //     "updated_at": "2024-01-31T15:01:24.000000Z"
  // }
  return (
    <div className=" w-[100%]">
      <div className=" px-3">
        <div className="flex justify-between">
          <div className=" flex justify-between w-[40%]">
            {/* <p>Spot</p> */}
            <p>Name</p>
          </div>
          <div className=" w-10"></div>
          <div className=" flex justify-between w-[50%]">
            {/* <p>Subscribers</p>
            <p>Subs. Status</p> */}
            <p>Email</p>
          </div>
        </div>
      </div>

      {referrals?.length === 0 ? (
        <div className=" flex flex-col items-center justify-center mt-8">
          <h2 className=" font-bold my-1 mb-2">No referrals yet</h2>
        </div>
      ) : (
        referrals?.map((referral, index) => (
          <BoardCard key={index} referral={referral} />
        ))
      )}
      <div className=" flex flex-col items-center justify-center mt-8">
        <h2 className=" font-bold my-1 mb-2">Total Earnings</h2>
        <AppButton
          buttonName={`N ${referrals === null ? "0" : referrals?.length * 250}`}
        />
        <p className=" mt-2">Next payout date:</p>
        <h2 className=" font-bold my-2">30 February, 2024</h2>
        <div className=" flex items-center justify-center">
          {/* <p className={` text-[#E18762] pr-2`}>
            <Link to="/account_info">Manage payout method</Link>
          </p>
          <IoIosArrowForward size={20} className={`text-[${orangeColor}]`} /> */}
        </div>
      </div>
    </div>
  );
};

const BoardCard = ({ referral }) => {
  return (
    <div className=" rounded-2xl p-1 px-3 bg-[#EDFCEF] shadow-md w-full my-3">
      <div className=" flex space-x-3 my-3 items-center ">
        <div className=" flex items-center ">
          {/* <div className="flex items-center">
            <div className="rounded-xl bg-[#CFA01C] border-[#CFA01C] border-2 w-12 h-12 text-center">
              <img
                src={leader?.avatar}
                alt={`${leader?.name} pix`}
                className="w-full h-full rounded-2xl"
              />
            </div>
          </div> */}
          <h2 className="font-medium text-base ">{referral?.name}</h2>
        </div>
        <div className=" flex ">
          <p>{referral?.email}</p>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
