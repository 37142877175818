import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaUser } from "react-icons/fa6";

const LeadersBoard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const token = Cookies.get("token");

  useEffect(() => {
    async function fetchLeaders() {
      const config = {
        url: "/get/leaderboard",
        method: "get",
        baseURL: "https://ajebuta-referral.ajebur.com/api",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(config);
        if (response.data.status) {
          setLeaderboard(response.data.data);
        } else setLeaderboard([]);
      } catch (error) {
        console.error(error);
        setLeaderboard([]);
      }
    }
    fetchLeaders();
  }, []);

  return (
    <div className=" w-[100%]">
      <div className=" flex justify-between items-end my-6">
        <LeaderCard key={leaderboard.id} leader={leaderboard[2]} index={3} />
        <LeaderCard key={leaderboard.id} leader={leaderboard[0]} index={1} />
        <LeaderCard key={leaderboard.id} leader={leaderboard[1]} index={2} />
      </div>
      <div className=" px-3">
        <div className="flex justify-between">
          <div className=" flex justify-between w-[50%]">
            <p>Spot</p>
            <p>Avatar</p>
            <p>Name</p>
          </div>
          <div className=" w-10"></div>
          <div className=" flex justify-between w-[32%]">
            <p>Referrals</p>
            <p>%</p>
          </div>
        </div>
      </div>

      {leaderboard?.map((leader, index) => (
        <BoardCard key={leader.id} leader={leader} index={index} />
      ))}
    </div>
  );
};

const BoardCard = ({ leader, index }) => {
  return (
    <div className=" rounded-2xl p-1 px-3 bg-[#EDFCEF] shadow-md w-[100%] my-3">
      <div className=" flex justify-between my-3 items-center space-x-2 ">
        <div className=" flex items-center space-x-2 flex-1">
          <div className="flex items-center space-x-2">
            <div className="rounded-xl bg-[#CFA01C] border-[#CFA01C] border-2 w-8 h-8 flex items-center justify-center ">
              <p className=" text-black text-xs font-medium ">#{index + 1}</p>
            </div>
            <div className="rounded-xl bg-[#CFA01C] border-[#CFA01C] border-2 w-10 h-10 flex items-center justify-center">
              <img
                src={leader?.avatar}
                alt={`${leader?.name} pix`}
                className="w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <p className=" font-medium text-base capitalize ">{leader?.name}</p>
        </div>
        <div className=" flex justify-between w-24 ">
          <div className="flex space-x-1 items-center">
            <FaUser size={14} />
            <h5>{leader?.referral_count}</h5>
          </div>
          <h5>{leader.total_referral_count}%</h5>
        </div>
      </div>
    </div>
  );
};

const LeaderCard = ({ index, leader }) => {
  return (
    <div className=" flex flex-col items-center">
      <div className="relative flex flex-col items-center mx-3">
        <div
          className={`${
            index === 1 ? "w-24 h-24" : " w-20 h-20"
          } rounded-2xl border-[#E18762] border-2`}
        >
          <img
            src={leader?.avatar}
            alt={`${leader?.name} pix`}
            className="w-full h-full rounded-2xl"
          />
        </div>
        <div className="absolute bottom-[-0.7rem] w-full flex justify-center">
          <div className="relative rounded-2xl w-8 h-8 bg-[#CFA01C] flex items-center justify-center">
            <p className="text-black font-bold text-center text-base">
              #{index}
            </p>
          </div>
        </div>
      </div>
      <h2 className="text-sm font-bold mt-4 capitalize">{leader?.name}</h2>
      {/* <div className=" flex justify-between w-[75%]">
        <h5>{leader?.referral_count}</h5>
        <h5>{leader.total_referral_count}%</h5>
      </div> */}
    </div>
  );
};

export default LeadersBoard;
