import React, { useState } from "react";
import logo from "../assets/logo.png";
import AppButton from "../components/app_button";
import QuestionAnsImg from "../assets/qa.png";
import { IoIosArrowDown } from "react-icons/io";
import { orangeColor } from "../colors";
import { Link } from "react-router-dom";

const LearnMore = () => {
  return (
    <div
      className={`max-w-screen-sm m-auto p-6 py-24 pt-3 flex flex-col items-center justify-between`}
    >
      <div>
        <h2 className=" font-bold text-[1.2rem]">
          Welcome to Ajebuta Affiliate Marketing Program (AJAM)
        </h2>
        <p className=" text-sm">
          Here are a few things you need to know about AJAM and how you can
          start getting paid monthly!
        </p>
      </div>
      <div className=" rounded-3xl my-6 p-4 bg-[#EDFCEF] w-full">
        {/* Dropdown menu */}
        <AffilateDropdown
          name="Why AJAM Needs You?"
          description="AJAM provides an opportunity to market and get paid, perhaps getting you a side income. Here is where you partner with Ajebuta and you get rewarded for doing so. We're connecting services and vendors with everyone and you are making this goal a reality."
        />
        <AffilateDropdown
          name="How It Works"
          description="Joining is easy! To register, firstly you need to download and signup on the Ajebuta app. Then copy your referral code in the profile and use it to register on AJAM."
        />
        <AffilateDropdown
          name="Commission Structure"
          description="You can start earning right away after registration. Get people to sign up on the Ajebuta app then get their referral code and add them as your referrals on the AJAM. Every new referral you add increases your commission. Ajebuta will automatically disburse your commission to you weekly. In general every person signed up earns you 250Naira."
        />
        <AffilateDropdown
          name="The Leader Board"
          description="If you fall into top 10 in the Ajam scheme for the week, you get to be paid at 300 Naira per signup. No 1 position on the leaders board gets 400 Naira per signup."
        />
      </div>
      <div className=" text-center">
        <h1 className=" text-xl">Ready to start earning?</h1>
        <p>
          Join our affiliate program now and become a part of our success story.
        </p>
      </div>
      <AppButton buttonName={<Link to="/register"> Get Started</Link>} />
      <div className=" flex flex-col justify-center items-center">
        <img src={QuestionAnsImg} alt="Q/A" className=" w-36" />
        <h1 className=" text-xl">Have questions?</h1>
        <p>Contact our affiliate support team at:</p>
        <div className=" py-3 pb-6 text-center">
          <h5>09072000001</h5>
          <h5>ajebuta.com@gmail.com</h5>
          <h5>www.ajebuta.com</h5>
        </div>
      </div>
      <div className=" flex flex-col justify-center items-center ">
        <img src={logo} alt="sally" width={100} />
        <p className={`pl-2 text-center text-xs`}>
          Copyright © 2024 Ajebuta All Rights Reserved
          <a className="ml-2" href="mailto:affiliates@ajebuta.com">
            affiliates@ajebuta.com
          </a>
        </p>
      </div>
    </div>
  );
};

function AffilateDropdown({ name, description }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className=" border-b-2 cursor-pointer p-2">
      <div
        className=" flex items-center justify-between"
        onClick={toggleDropDown}
      >
        <h5>{name}</h5>
        <div
          className={` cursor-pointer transition-transform duration-300 transform ${
            isOpen ? "rotate-180" : ""
          } text-[${orangeColor}] `}
        >
          <IoIosArrowDown />
        </div>
      </div>
      {isOpen && (
        <div
          className="right-0 mt-2  duration-300 transform"
          onClick={toggleDropDown}
        >
          <ul className={`py-2 text-sm text-[#6F736D]`}>
            <li className="px-2 py-2">{description}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default LearnMore;
