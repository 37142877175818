import React, { useEffect, useState } from "react";
import salyImage from "../assets/saly_ajebuta.png";
import emailImg from "../assets/email.png";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye, FaLock, FaSpinner } from "react-icons/fa6";
import { IoMdEyeOff } from "react-icons/io";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const [view, setView] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const token = Cookies.get("token");

  useEffect(() => {
    token && navigate("/leaderboard");
  }, [token]);

  async function handleLogin() {
    if (details.email && details.password) {
      setLoader(true);
      const data = {
        password: details.password,
        email: details.email,
      };
      try {
        const response = await axios.post(
          `https://ajebuta-referral.ajebur.com/api/affiliate/login`,
          data
        );

        if (response?.data.status) {
          toast.success(response?.data.message);
          Cookies.set("token", response.data.data.token);
          setTimeout(() => navigate("/leaderboard"), 2000);
        } else {
          toast.warn(response?.data.message);
        }
      } catch (error) {
        console.error(error);
        toast.error(error?.response?.data.message);
      } finally {
        setLoader(false);
      }
    } else {
      toast.warn("All fields must be filled!");
    }
  }

  return (
    <div
      className={`max-w-screen-sm  m-auto p-4 py-24 pt-3 flex flex-col items-start`}
    >
      <div className="w-full flex justify-between items-center ">
        <div className=" w-[40%]">
          <h6 className=" text-[#6F736D]">
            Join Ajebuta Affiliate Program & start earning!
          </h6>
        </div>
        <img src={salyImage} alt="sally" width={100} />
      </div>
      <div className="mt-3 mb-14">
        <h1>Login</h1>
        <p>Kindly provide your email and password.</p>
      </div>
      <div>
        <div>
          <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
            <img src={emailImg} alt="email" className=" w-8 mx-auto" />
            <input
              type="email"
              name="email"
              required={true}
              placeholder="Email"
              value={details.email}
              onChange={(e) =>
                setDetails({ ...details, email: e.target.value })
              }
              className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
            />
          </div>
          <div>
            <div className=" border border-gray-300 rounded-2xl flex items-center bg-[#D7EDD9] w-[90vw] h-14 p-3 justify-between focus-within:border-gray-300 mb-4">
              <FaLock size={30} color="grey" />
              <input
                type={view ? "text" : "password"}
                name="password"
                required={true}
                placeholder="Password"
                value={details.password}
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
                className=" rounded-2xl rounded-s-none p-3 w-[90%] focus:outline-none bg-transparent"
              />
              <div onClick={() => setView((o) => !o)}>
                {view ? (
                  <IoMdEyeOff size={20} color="grey" />
                ) : (
                  <FaEye size={20} color="grey" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 w-full ">
        {loader ? (
          <button
            disabled
            className="text-white font-normal py-3 px-auto w-full rounded-xl capitalize"
          >
            <FaSpinner className="animate-spin mx-auto" />
          </button>
        ) : (
          <button
            onClick={handleLogin}
            className="text-white font-normal py-3 px-auto w-full rounded-xl capitalize"
          >
            login
          </button>
        )}
      </div>
      <div className="flex space-x-2 justify-center w-full mt-4">
        <p className="text-base">Don't have an account?</p>
        <Link to="/register">
          <p className="text-base capitalize text-green-500 cursor-pointer">
            register
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Login;
