import React from "react";
import AppButton from "../components/app_button";
import { IoIosArrowForward } from "react-icons/io";
import { FaArrowTrendUp } from "react-icons/fa6";
import salyImage from "../assets/saly_ajebuta.png";
import newImg from "../assets/new.png";
import logo from "../assets/logo.png";
import { orangeColor } from "../colors";
import { Link } from "react-router-dom";

const OnboardingScreen = () => {
  return (
    <div className={`max-w-screen-sm  m-auto p-6 py-24 pt-3 flex flex-col  `}>
      {/* First div for introduction */}
      <div className="my-8 mt-3">
        <div className=" flex items-center">
          <FaArrowTrendUp className={` text-[#CFA01C] pr-1`} size={30} />
          <h4 className=" pr-1">Introducing</h4>
          <img src={newImg} alt="" />
        </div>
        <h1>Ajebuta Affiliate Marketing Program (AJAM)</h1>
        <p>
          Welcome to Ajebuta's Affiliate Program! We're thrilled to invite you
          to partner with us and earn commissions by promoting our products.
        </p>
      </div>
      {/* Second div for image and text */}
      <div className=" w-[100%] flex justify-between mb-12">
        <div>
          <h6>Join our Affiliate Program and start earning!</h6>
          <p>
            Joining is easy and you can start earning in just a few simple
            steps!
          </p>
        </div>
        <img src={salyImage} alt="sally" width={100} />
      </div>
      <div className=" flex flex-col justify-center items-center cursor-pointer">
        {/* App Button */}
        <AppButton buttonName={<Link to="/register">Get Started</Link>} />
        <div className=" flex items-center justify-center mt-3">
          <p className={` text-[#E18762] pr-2`}>
            <Link to="/learn_more">Learn more</Link>
          </p>
          <IoIosArrowForward size={20} className={`text-[${orangeColor}]`} />
        </div>
      </div>

      <div className=" flex flex-col justify-center items-center mt-28">
        <img src={logo} alt="sally" width={100} />
        <p className={`pl-2 text-center text-xs`}>
          Copyright © 2024 Ajebuta All Rights Reserved
          <a className="ml-2" href="mailto:affiliates@ajebuta.com">
            affiliates@ajebuta.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default OnboardingScreen;
