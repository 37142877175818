import React from "react";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div>
      <div className="hidden sm:flex h-screen justify-center items-center text-center ">
        <div>
          <p className="font-bold text-2xl ">
            Please Use a Mobile Phone to Access this Site!
          </p>
          <p className="text-sm font-medium">Desktop Version coming soon...</p>
        </div>
      </div>
      <div className="block sm:hidden">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
